<template>
  <section>
    <v-container fluid class="pa-0">
      <v-layout v-scroll="onScroll" row wrap>
        <v-flex xs12>
          <v-parallax
            :src="this.$store.state.brand.brand.city_splash_primary"
            class="first"
            height="550"
          >
            <v-container fill-height>
              <v-layout row align-center wrap>
                <v-flex align-center column hidden-xs-only>
                  <v-flex xs12 sm9 md8 ml-5>
                    <h2
                      class="white--text display-3"
                      style="font-size: 70px !important"
                    >
                      <!-- {{ this.$store.state.brand.brand.app_name }} -->
                      Resultados en Blockchain
                    </h2>
                    <h3 class="white--text display-1">
                      {{
                        '¡Primera lotería en el mundo en usar la tecnología blockchain ' +
                          this.$store.state.brand.brand.city_name
                      }}
                    </h3>
                    <v-progress-linear
                      color="primary"
                      height="2"
                      value="15"
                      background-color="white"
                    ></v-progress-linear>
                    <h3 class="white--text title">
                      Certificación de los resultados de La Rionegrina.
                    </h3>
                  </v-flex>
                </v-flex>
                <v-flex align-center column justify-center hidden-sm-and-up>
                  <v-flex xs12>
                    <h2 class="white--text display-1">
                      Resultados en Blockchain
                    </h2>
                    <h3 class="white--text title mt-3">
                      {{
                        '¡Primera lotería en el mundo en usar la tecnología blockchain ' +
                          this.$store.state.brand.brand.city_name
                      }}
                    </h3>
                    <v-progress-linear
                      color="primary"
                      height="2"
                      value="15"
                      background-color="white"
                    ></v-progress-linear>
                    <h3 class="white--text headline">
                      Certificación de los resultados de La Rionegrina.
                    </h3>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-container>
          </v-parallax>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container class="container-info__columns">
      <v-layout row wrap>
        <v-flex xs12 mt-5>
          <v-layout align-center>
            <v-flex xs12 text-xs-center>
              <h1 class="grey--text text--darken-3">
                <!-- <span>{{ this.$store.state.brand.brand.city_name }}</span> -->
                <span class="font-weight-light"
                  >Hacia una Lotería Inteligente.</span
                >
              </h1>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-between class="mt-5">
            <v-flex xs12 md3>
              <h2 class="text-xs-center mb-3">
                <v-icon size="48" color="primary">
                  $vuetify.icons.oscity_settings
                </v-icon>
              </h2>
              <h4 class="primary--text text-xs-center mb-3">
                ¿Qué es blockchain?
              </h4>
              <p class="subheading text-xs-center">
                Es una base de datos distribuida donde se lleva registro de cada
                transacción o intercambio que se realice. Lo innovador de
                blockchain es que el registro se encuentra distribuido y es
                imposible su modificación una vez que un dato ha sido
                incorporado.
              </p>
            </v-flex>
            <v-flex xs12 md3>
              <h2 class="text-xs-center mb-3">
                <v-icon size="48" color="primary">
                  $vuetify.icons.oscity_edit
                </v-icon>
              </h2>
              <h4 class="primary--text text-xs-center mb-3">
                ¿Cómo lo usamos?
              </h4>
              <p class="subheading text-xs-center">
                Somos la primera Lotería del mundo en utilizar esta tecnología
                para la certificación de los sorteos.Utilizamos esta tecnología
                para elevar los niveles de transparencia y confianza, dando la
                seguridad que nadie ha modificado la información de los sorteos.
              </p>
            </v-flex>
            <v-flex xs12 md3>
              <h2 class="text-xs-center mb-3">
                <v-icon size="48" color="primary">
                  $vuetify.icons.oscity_city_hall
                </v-icon>
              </h2>
              <h4 class="primary--text text-xs-center mb-3">
                Conoce más sobre la experiencia
              </h4>
              <p class="subheading text-xs-center">
                Si estás interesado en conocer los detalles sobre cómo
                utilizamos blockchain y los alcances de la experiencia, accede
                al siguiente informe
                <a
                  class="link_noLine"
                  href="https://firebasestorage.googleapis.com/v0/b/rionegro-cmp.appspot.com/o/brand%2FBlockchain_en_la_Loteri%CC%81a_Rio_Negro.pdf?alt=media&token=929568a2-3b76-4044-92e4-9992bcd8c5cc"
                  target="_blank"
                  >aquí.</a
                >
              </p>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container>
      <v-layout row wrap align-center>
        <v-flex xs12 text-xs-center mt-5>
          <h3 class="grey--text text--darken-3 title">
            Encuentra aquí los resultados de la quiniela certificados.
          </h3>
        </v-flex>
        <v-flex xs2 md2 mt-4 text-xs-center></v-flex>
        <v-flex xs8 md8 mt-4 text-xs-center>
          <v-form
            ref="formCertificate"
            v-model="validCertificate"
            lazy-validation
            @submit.prevent="viewCertificate"
          >
            <v-autocomplete
              v-model="selectedSorteo"
              :items="items"
              :loading="isLoading"
              :rules="rules"
              item-text="text"
              item-value="ref"
              hide-details
              label="Certificados"
              placeholder="Buscar contacto por fecha (yyyy-mm-dd) o número del sorteo *"
              prepend-inner-icon="search"
              return-object
              required
              outline
            >
              <template slot="no-data">
                <v-list-tile>
                  <v-list-tile-title>
                    Empieza a escribir para buscar por:
                    <strong>fecha o número del sorteo</strong>
                  </v-list-tile-title>
                </v-list-tile>
              </template>
              <template slot="selection" slot-scope="{ item }">
                <span v-text="item.text"></span>
              </template>
            </v-autocomplete>
            <v-flex xs12 class="text-xs-center mt-4">
              <v-btn
                :disabled="!selectedSorteo"
                color="primary"
                type="submit"
                class="ma-0"
              >
                Ver certificado
              </v-btn>
            </v-flex>
          </v-form>
        </v-flex>
        <v-flex xs2 md2 mt-4 text-xs-center></v-flex>
      </v-layout>
    </v-container>
    <v-container fluid class="pa-0">
      <v-layout row wrap>
        <v-flex xs12 text-xs-center mt-5>
          <v-parallax
            :src="this.$store.state.brand.brand.city_splash_secondary"
            class="last"
            height="450"
          >
            <v-container fill-height>
              <v-layout row wrap align-center justify-center hidden-xs-only>
                <v-flex xs12 sm8>
                  <h1 class="white--text display-2">
                    {{ this.$store.state.brand.brand.city_slogan }}
                  </h1>
                  <h4 class="white--text headline">
                    La utilización de blockchain es un paso en el camino hacia
                    una lotería innovadora, que busca mejorar de manera
                    constante la transparencia y el servicio a los ciudadanos.
                  </h4>
                </v-flex>
              </v-layout>
              <v-layout row wrap align-center justify-center hidden-sm-and-up>
                <v-flex xs12 sm8>
                  <h1 class="white--text display-1">
                    {{ this.$store.state.brand.brand.city_slogan }}
                  </h1>
                  <h4 class="white--text title">
                    La utilización de blockchain es un paso en el camino hacia
                    una lotería innovadora, que busca mejorar de manera
                    constante la transparencia y el servicio a los ciudadanos.
                  </h4>
                </v-flex>
              </v-layout>
            </v-container>
          </v-parallax>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container>
      <v-layout row wrap justify-space-between>
        <v-flex xs12 text-xs-center mt-5 mb-5>
          <h1 class="mb-0 font-weight-light grey--text text--darken-2">
            Lotería de Rio Negro, para obras de acción social.
          </h1>
          <h3 class="mt-0" style="color:#5b5b5b">
            Lo que das, vuelve
          </h3>
        </v-flex>
        <v-flex
          v-if="!$auth.loggedIn"
          xs12
          text-xs-center
          mb-5
          hidden-md-and-up
        >
          <nuxt-link to="login">
            <v-btn color="primary">
              INGRESAR
            </v-btn>
          </nuxt-link>
          <nuxt-link to="register">
            <v-btn color="primary">
              CREAR CUENTA
            </v-btn>
          </nuxt-link>
        </v-flex>
        <!-- <v-flex v-else xs12 text-xs-center mb-5 hidden-md-and-up>
          <nuxt-link to="/citizen/listener/create-v1">
            <v-btn color="primary">
              Comenzar
            </v-btn>
          </nuxt-link>
        </v-flex> -->
      </v-layout>
    </v-container>
  </section>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      selectedHash: null,
      selectedSorteo: null,
      isLoading: false,
      items: [],
      validCertificate: false,
      rules: [v => !!v || 'El campo es requerido']
    }
  },
  beforeMount() {
    this.checkFirebase()
    this.getData()
  },
  mounted() {
    this.getMinibingoCerts()
    this.$store.commit('SET_HOME_TOP', true)
  },
  destroyed() {
    this.$store.commit('SET_HOME_TOP', false)
  },
  methods: {
    onScroll(e) {
      if (
        e &&
        e.target &&
        e.target.scrollingElement &&
        e.target.scrollingElement.scrollTop >= 10
      ) {
        this.$store.commit('SET_HOME_TOP', false)
      } else {
        this.$store.commit('SET_HOME_TOP', true)
      }
    },
    viewCertificate() {
      if (this.$refs.formCertificate.validate()) {
        window.open(this.selectedSorteo.link, '_blank')
      }
    },
    getCertificate() {
      return this.$firebaseSingletally
        .firestore()
        .collection('certificateDataCollection')
        .get()
    },
    getData() {
      const self = this
      this.getCertificate().then(function(certificates) {
        certificates.forEach(doc => {
          const data = {
            ref: doc.ref.path,
            ...doc.data()
          }
          data.text = `${data.fecha_sorteo} - ${data.sorteo} - ${
            data.sorteo_nombre
              ? data.sorteo_nombre.name
              : data.nombre
              ? data.nombre
              : ''
          }`
          if (data.is_certified) {
            self.items.push(data)
          }
        })
        self.items.sort(function(a, b) {
          return parseInt(b.sorteo) - parseInt(a.sorteo)
        })
      })
    },
    async getMinibingoCerts() {
      const self = this
      // eslint-disable-next-line camelcase
      const certs_minibingo = await axios.post(
        'https://us-central1-neuquen-certificates.cloudfunctions.net/getCertsMinibingo'
      )
      certs_minibingo.data.forEach(doc => {
        const data = {
          ref: doc.link,
          ...doc
        }
        data.text = `${data.fecha_sorteo} - ${data.sorteo} - ${
          data.sorteo_nombre
            ? data.sorteo_nombre.name
            : data.nombre
            ? data.nombre
            : ''
        }`
        if (data.is_certified) {
          self.items.push(data)
        }
      })
      self.items.sort(function(a, b) {
        return parseInt(b.sorteo) - parseInt(a.sorteo)
      })
    },
    checkFirebase() {
      const self = this
      this.$firebaseSingletally.auth().onAuthStateChanged(
        function(user) {
          if (user) {
            self.currentUser = user.displayName
            self.currentUserEmail = user.email
            user.getIdToken().then(function(accessToken) {})
          } else {
          }
        },
        function(error) {
          console.error(error)
        }
      )
    }
  }
}
</script>

<style lang="stylus" scoped>
>>>.v-parallax.first {
  box-shadow 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
  .v-parallax__image {
    filter brightness(45%);
  }
  .v-parallax__content {
    h1 {
      font-weight 900
      line-height 1 !important
    }
  }
}
>>>.v-parallax.last {
  box-shadow 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
  .v-parallax__image {
    filter brightness(45%)
  }
  .v-parallax__content {
    h1 {
      font-weight 700
      margin 0 2.5rem 2.5rem
    }
    h4 {
      font-weight 400
      line-height !important
    }
  }
}
h1 {
  font-size 3rem
  font-weight 100
  span {
    font-weight 900
  }
}
h2 {
  font-weight 700
  i {
    font-size 3.2rem
  }
}
h3 {
  font-size 2.5rem
}
h4 {
  font-size 1.7rem
}
.link_noLine {
  text-decoration: none;
}
@media only screen and (min-width: 1264px) {
  .container-info__columns{
    max-width: 900px !important;
  }
}
</style>
